exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-besplatnye-programmy-tsx": () => import("./../../../src/pages/besplatnye-programmy.tsx" /* webpackChunkName: "component---src-pages-besplatnye-programmy-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-istorii-uspeha-tsx": () => import("./../../../src/pages/istorii-uspeha.tsx" /* webpackChunkName: "component---src-pages-istorii-uspeha-tsx" */),
  "component---src-pages-karta-saita-tsx": () => import("./../../../src/pages/karta-saita.tsx" /* webpackChunkName: "component---src-pages-karta-saita-tsx" */),
  "component---src-pages-kontakty-tsx": () => import("./../../../src/pages/kontakty.tsx" /* webpackChunkName: "component---src-pages-kontakty-tsx" */),
  "component---src-pages-magazin-tsx": () => import("./../../../src/pages/magazin.tsx" /* webpackChunkName: "component---src-pages-magazin-tsx" */),
  "component---src-pages-obuchenie-tsx": () => import("./../../../src/pages/obuchenie.tsx" /* webpackChunkName: "component---src-pages-obuchenie-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-preview-tsx": () => import("./../../../src/templates/post-preview.tsx" /* webpackChunkName: "component---src-templates-post-preview-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-success-story-tsx": () => import("./../../../src/templates/successStory.tsx" /* webpackChunkName: "component---src-templates-success-story-tsx" */),
  "component---src-templates-test-tsx": () => import("./../../../src/templates/test.tsx" /* webpackChunkName: "component---src-templates-test-tsx" */)
}

